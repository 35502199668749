import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import About from '../views/About.vue'
import Resume from '../views/Resume.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Andrew Ludwig';
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'About',
    component: About,
    meta: { title: `${DEFAULT_TITLE} - About` }
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta: { title: `${DEFAULT_TITLE} - About` }
  },
  {
    path: '/Resume',
    name: 'Resume',
    component: Resume,
    meta: { title: `${DEFAULT_TITLE} - Resume` }
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
    meta: { title: `${DEFAULT_TITLE} - Contact` }
  }
]

const router = new VueRouter({
  routes,
  linkActiveClass: 'active'
})
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta?.title || DEFAULT_TITLE;
  });
});

export default router
