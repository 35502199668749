




























































































import Vue from 'vue'
import Component from 'vue-class-component'
import link from './model/link'

@Component({})
export default class App extends Vue {
    goDark = false
    drawer = false
    links: link[] = [
      {
        name: 'About',
        href: 'About'
      },
      {
        name: 'Resume',
        href: 'Resume'
      },
      {
        name: 'Contact',
        href: 'Contact'
      }
    ]

    handledarkmode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
}
