



















import 'vue-pdf-app/dist/icons/main.css'
import VuePdfApp from 'vue-pdf-app'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    VuePdfApp
  }
})
export default class Resume extends Vue {
   publicPath = process.env.BASE_URL
}
